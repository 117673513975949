<template>
  <div class="shell-box">
    <el-breadcrumb separator="/" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/myHome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{ path: '/routerPolicyManagement/correctionSheetManagement' }"
        >批单列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>批单修改</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="form">
      <policyForm
        ref="correction"
        :detailData="correctionDetails"
        :permissions="policyDetailPermissions"
        :createCorrectionType="$route.params.approvalType"
        :uploadData="uploadData"
      />
    </div>
    <div class="operating-box">
      <el-button size="mini" @click="handleBack">返回</el-button>
      <el-button size="mini" type="primary" @click="handleReset" plain
        >重置</el-button
      >
      <!-- <el-button
        v-if="pageConfig.bottomUploadShow"
        size="mini"
        @click="uploadFile"
        type="warning"
        plain
        >上传附件</el-button
      > -->
      <el-button size="mini" type="primary" @click="handlePolicyUpdate"
        >提交</el-button
      >
    </div>
    <DragMultipleUpload
      :show.sync="showUpload"
      :dataEcho="dataBackShow"
      :fileSzie="10"
      :canDeletOld="false"
      @submitData="submitUploadData"
    ></DragMultipleUpload>
  </div>
</template>

<script>
import policyForm from "@/views/CorrectionSheetManagement/components/CorrectionEditDetail";
import DragMultipleUpload from "@/components/DragMultipleUpload";
import { getCorrectionDetails, policyCorrection } from "@/api/policy";
import { hasPerms, dataPermissions } from "@/utils/auth";
import { getPageConfig } from "@/views/CorrectionSheetManagement/pagesConfig.js";
export default {
  components: { policyForm, DragMultipleUpload },
  data() {
    return {
      //dataBackShow:[{urls:[{url:'1346',name:'46546346',suffix:'doc' ,id:123}],selectValue:1},{urls:[{url:'1346',name:'46546346',suffix:'doc',id:123}],selectValue:1},{urls:[{url:'1346',name:'46546346',suffix:'doc',id:123}],selectValue:1}],
      dataBackShow: [],
      correctionDetails: {},
      oldCorrectionDetails: {},
      attachmentType: "",
      attachments: {},
      uploadData: {},
      showUpload: false,
      pageConfig: {},
      policyDetailPermissions: {
        agentId: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "agentName"
        ),
        // "desc": "代理人",
        applicantMode: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "applicantMode"
        ),
        // "desc": "投保方式",
        lastPolicyNo: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "lastPolicyNo"
        ),
        // "desc": "上一年保单号",
        endDate: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "endDate"
        ),
        // "desc": "到期时间",
        city: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "city"
        ),
        // "desc": "所属地区",
        // name: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "name"),
        // "desc": "保单号",
        province: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "province"
        ),
        // "desc": "所属省份",
        applicantType: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "applicantType"
        ),
        // "desc": "投保类型",
        acceptCompanyList: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "acceptCompanyList"
        ),
        // "desc": "承保公司"`
        extensionClause: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "extensionClause"
        ),
        // "desc": "免费扩展条款",
        specialAgreement: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "specialAgreement"
        ),
        // "desc": "特别约定",
        applicantStore: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "applicantStore"
        ),
        // "desc": "投保门店",
        absoluteDeductible3: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "absoluteDeductible3"
        ),
        // "desc": "未扩展不计免赔",
        absoluteDeductible2: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "absoluteDeductible2"
        ),
        // "desc": "扩展不计免赔2",
        salesmanId: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "salesmanName"
        ),
        // "desc": "业务员",
        agentNo: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "agentNo"
        ),
        // "desc": "代理人编码",
        beneficiaryList: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "beneficiaryList"
        ),
        // "desc": "受益人",
        policyHolderList: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "policyHolderList"
        ),
        // "desc": "投保人",
        totalPremium: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "totalPremium"
        ),
        // "desc": "总保费",
        ep: dataPermissions(`M25_${this.$route.meta.policyType}-View`, "ep"),
        // "desc": "电子保单",
        projectRemark: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "projectRemark"
        ),
        // "desc": "项目备注",
        policyPlanList: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "policyPlanList"
        ),
        // "desc": "保障计划",
        policySubjectList: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "policySubjectList"
        ),
        // "desc": "标的信息",
        absoluteDeductible: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "absoluteDeductible"
        ),
        // "desc": "扩展不计免赔1",
        policyInsuredList: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "insured"
        ),
        // "desc": "被保险人",
        projectName: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "projectName"
        ),
        // "desc": "项目名称",
        startDate: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "startDate"
        ),
        // "desc": "起保时间",
        enquiryNo: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "enquiryNo"
        ),
        // "desc": "询价单号",

        //批文
        approval: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "approval"
        ),
        // "desc": "其他类型"
        orderType: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "orderType"
        ),
        // "desc": "户名"
        accountName: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "accountName"
        ),
        // "desc": "开户行"
        accountBlank: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "accountBlank"
        ),
        // 银行账户
        accountNumber: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "accountNumber"
        ),
        //设领款人证件类型
        idType: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "idType"
        ),
        //证件号码
        idNumber: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "idNumber"
        ),
        //联系电话
        phone: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "phone"
        ),
        recorder: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "recorder"
        ),
        // 录单员
        specialVersion: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "specialVersion"
        ),
        // 特别说明
        fromSourceTypeName: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "fromSourceTypeName"
        ),
        // 保单来源
        insuranceOfDate: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "insuranceOfDate"
        ),
        // 投保日期
        salesmanDeptName: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "salesmanDeptName"
        ),
        // 所属部门
        insuredCategory: dataPermissions(
          `M25_${this.$route.meta.policyType}-View`,
          "insuredCategory"
        ),
        // 险种类型
      },
    };
  },
  created() {
    this.pageConfig = getPageConfig(this.$route.meta.policyType);
    this.getDetail();
  },
  methods: {
    getDetail() {
      getCorrectionDetails({
        orderId: this.$route.params.orderId,
        riderId: this.$route.params.riderId || "",
        approvalType: this.$route.params.approvalType,
      }).then(
        (res) => {
          this.correctionDetails = res.data;
          this.oldCorrectionDetails = JSON.parse(JSON.stringify(res.data));
          let dataBackShow = [];
          let idInd = 1;
          res.data.attachments
            ? Object.keys(res.data.attachments).forEach(function (key) {
                let type = (key + "").split("-");
                dataBackShow.push({
                  type: type[0] == "qt" ? undefined : type[0],
                  selectValue: Number(type[1]),
                  urls: res.data.attachments[key].map((val) => {
                    idInd++;
                    return {
                      suffix: val.fileType,
                      name: val.fileName,
                      url: val.url,
                      id: idInd,
                    };
                  }),
                });
              })
            : "";
          this.dataBackShow = dataBackShow;
          // let typeList = res.data.attachmentType.split(",");
          // let showData = [];
          // typeList.forEach((item, index) => {
          //   let selects = item.split("-");
          //   if (res.data.attachments[item]) {
          //     showData.push({
          //       selectValue: parseInt(selects[selects.length - 1]),
          //       urls: res.data.attachments[item].map((t) => {
          //         return {
          //           url: t.url,
          //           name: t.fileName,
          //           suffix: t.fileType,
          //           id: t.id,
          //         };
          //       }),
          //     });
          //   } else {
          //     showData.push({
          //       selectValue: parseInt(selects[selects.length - 1]),
          //       urls: [],
          //     });
          //   }
          // });
          // this.dataBackShow = showData;
        },
        (err) => {
          this.$router.replace({ name: `policyManagement` });
        }
      );
    },
    handleBack() {
      let { data, type } = this.$refs.correction.submitData();
      policyCorrection(
        Object.assign(
          {
            riderStatus: 3, //继续批单传3
            riderId: this.$route.params.riderId, //新建不传
            orderId: this.$route.params.orderId,
            approvalType: this.$route.params.approvalType,
          },
          data
        )
      ).then((res) => {
        this.$router.replace({
          name: `correctionSheetManagement${this.$route.meta.policyType}`,
        });
      });
    },
    async handlePolicyUpdate() {
      let data = await this.$refs.correction.handleSubmit();
      data = this.handleSequence(data, [
        "beneficiaryList",
        "policyHolderList",
        "policyInsuredList",
      ]);
      if (this.$route.meta.policyType != 1) {
        data.attachmentType = "zjxx,qtxx,yyzz,kpxx";
      }
      policyCorrection(
        Object.assign(
          {
            riderStatus: 1, //提交批改传1
            riderId: this.$route.params.riderId, //新建不传
            orderId: this.$route.params.orderId,
            approvalType: this.$route.params.approvalType,
          },
          data
        )
      ).then((res) => {
        this.$router.replace({
          name: `correctionSheetManagement${this.$route.meta.policyType}`,
        });
      });
    },
    uploadFile() {
      this.showUpload = true;
    },
    submitUploadData(data) {
      let typeNames = data.map((item) => {
        return `${item.type}-${item.selectValue}`;
      });
      let attachments = {};
      data.map((item, index) => {
        attachments[typeNames[index]] = item.urls.map((t, i) => {
          return {
            attachmentType: typeNames[index],
            bussId: "1",
            fileName: t.name,
            fileType: t.suffix,
            id: t.id,
            sort: i,
            url: t.url,
          };
        });
      });
      this.uploadData = {
        attachmentType: typeNames.toString(),
        attachments,
      };
      this.showUpload = false;
    },
    handleReset() {
      this.correctionDetails = JSON.parse(
        JSON.stringify(this.oldCorrectionDetails)
      );
      let typeList = this.correctionDetails.attachmentType.split(",");
      let showData = [];
      typeList.forEach((item, index) => {
        let selects = item.split("-");
        if (this.correctionDetails.attachments[item]) {
          showData.push({
            selectValue: parseInt(selects[selects.length - 1]),
            urls: this.correctionDetails.attachments[item].map((t) => {
              return {
                url: t.url,
                name: t.fileName,
                suffix: t.fileType,
                id: t.id,
              };
            }),
          });
        } else {
          showData.push({
            selectValue: parseInt(selects[selects.length - 1]),
            urls: [],
          });
        }
      });
      this.dataBackShow = showData;
    },
    handleSequence(obj = {}, list = [], sort = "sort") {
      if (list && list.length) {
        list.forEach((item) => {
          if (obj[item].length) {
            obj[item].forEach((p, i) => {
              p[sort] = i;
            });
          }
        });
      }
      return obj;
    },
  },
};
</script>

<style lang="scss" scoped>
.shell-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  .form {
    flex: 1;
    overflow: hidden;
  }
  .breadcrumb {
    padding: 10px 0;
    ::v-deep .el-breadcrumb__inner.is-link {
      color: rgba(0, 128, 255, 1);
    }
  }
  .operating-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: calc(100% - 196px);
    height: 50px;
    padding: 0 24px;
    background: #ffffff;
    box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    z-index: 99;
  }
}
</style>
